import Intro from './Intro';
import Link from './Link';
import Logo from './Logo';

export default function Hero() {
  return (
    <div className='flex flex-col justify-center min-h-[80vh] my-14'>
      <div className='text-white grid sm:grid-cols-3 my-auto'>
        <Intro />
        <Logo />
        <Link />
      </div>
    </div>
  );
}
