import { configureStore } from '@reduxjs/toolkit';
import navBarSlice from './features/navBar/navBarSlice';

const store = configureStore({
  reducer: {
    navBar: navBarSlice,
  },
});

export default store;
