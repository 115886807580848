import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  navBarOpen: false,
};

const navBarSlice = createSlice({
  name: 'navBar',
  initialState,
  reducers: {
    toggleNavBar(state) {
      state.navBarOpen = !state.navBarOpen;
    },
  },
});

export const { toggleNavBar } = navBarSlice.actions;

export default navBarSlice.reducer;
