import Link2 from './Link2';
import Links from './Links';
import Logo from './Logo';
import Toggler from './Toggler';
import { useSelector } from 'react-redux';

export default function NavBar() {
  const dropLink = useSelector((store) => store.navBar.navBarOpen);
  return (
    <div className='text-white p-2 navbar-bg rounded-xl m-5 transition-all duration-300'>
      <nav className=' flex'>
        {/* <div> */}
        <Logo />

        <Toggler />

        <Links />
      </nav>
      <div className='transition-all duration-300'>{dropLink && <Link2 />}</div>
    </div>
  );
}
