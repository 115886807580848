export default function Links() {
  return (
    <div className='relative hidden h-auto ml-auto mr-5 font-bold md:block'>
      <ul className='hidden space-x-5 sm:block md:space-x-3'>
        <li className='inline-block p-2'>
          <a href='/' className='hover:text-indigo-700'>
            Home
          </a>
        </li>
        <li className='inline-block p-2'>
          <a href='#about' className='hover:text-indigo-700'>
            About
          </a>
        </li>
        <li className='inline-block p-2'>
          <a href='#service' className='hover:text-indigo-700'>
            Services
          </a>
        </li>
        <li className='inline-block p-2'>
          <a href='#project' className='hover:text-indigo-700'>
            Projects
          </a>
        </li>
        <li className='inline-block p-2'>
          <a href='#contact' className='hover:text-indigo-700'>
            Contact
          </a>
        </li>
      </ul>
    </div>
  );
}
