export default function About() {
  return (
    <div
      className='grid grid-cols-1 gap-5 p-5 text-white border-2 rounded-lg navbar-bg my-14 border-purple-950 md:grid-cols-3'
      id='about'
    >
      <div className='items-start w-full col-span-1 h-80'>
        <img
          src='./naol.png'
          alt='profile'
          className='object-cover object-top w-full h-full rounded-3xl'
        />
      </div>
      <div className='col-span-2 p-5'>
        <div className='mb-4 text-3xl font-bold text-purple-600'>About Me</div>
        <div className='font-semibold text-justify'>
          My name is <span className='text-purple-700'> Naol Kasinet</span>, and I am a full stack
          developer with one year of experience specializing in React, Next.js, Django, and Go. I
          have a proven ability to develop complete websites featuring robust backends and
          attractive frontends. My skill set allows me to create seamless, efficient, and visually
          appealing web applications that meet modern standards and user expectations. With a strong
          foundation in both front-end and back-end technologies, I am equipped to deliver
          comprehensive solutions tailored to various project requirements.
        </div>
      </div>
    </div>
  );
}
