export default function Link2() {
  return (
    <div className='md:hidden transition-all duration-300 font-bold'>
      <ul className='p-3 divide-y-2'>
        <li className=' p-2'>
          <a href='#home' className='hover:text-gray-300'>
            Home
          </a>
        </li>
        <li className='p-2'>
          <a href='#about' className='hover:text-gray-300'>
            About
          </a>
        </li>
        <li className='p-2'>
          <a href='#service' className='hover:text-gray-300'>
            Services
          </a>
        </li>
        <li className='p-2'>
          <a href='#project' className='hover:text-gray-300'>
            Projects
          </a>
        </li>
        <li className='p-2'>
          <a href='#contact' className='hover:text-gray-300'>
            Contact
          </a>
        </li>
      </ul>
    </div>
  );
}
