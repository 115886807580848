import {
  About,
  Footer,
  Hero,
  NavBar,
  Service,
  Skill,
  Projects,
} from './components';

function App() {
  return (
    <div className='bg p-3 space-y-20'>
      <NavBar />
      <Hero />
      <About />
      <Skill />
      <Service />
      <Projects />

      <Footer />
    </div>
  );
}

export default App;
