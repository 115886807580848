export default function Service() {
  return (
    <div className='my-16 text-justify text-white' id='service'>
      <div className='container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto mt-5 xl:px-0'>
        <div className='text-center'>
          <h2 className='mb-1 text-3xl font-extrabold leading-tight text-purple-600'>Services</h2>
          <p className='mb-12 text-lg text-purple-900'>
            Here is a few of the awesome Services i provide.
          </p>
        </div>
        <div className='w-full'>
          <div className='flex flex-col w-full mb-10 sm:flex-row'>
            <div className='w-full mb-10 sm:mb-0 sm:w-1/2'>
              <div className='relative h-full ml-0 mr-0 sm:mr-10'>
                <span className='absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-indigo-500 rounded-lg'></span>
                <div className='relative h-full p-5 bg-gray-100 border-2 border-indigo-500 rounded-lg'>
                  <div className='flex items-center -mt-1'>
                    <h3 className='my-2 ml-3 text-lg font-bold text-indigo-600'>
                      Full Stack Development
                    </h3>
                  </div>
                  <p className='mt-3 mb-1 text-xs font-medium text-indigo-600 uppercase'>
                    ------------
                  </p>
                  <p className='mb-2 text-gray-600'>
                    Building complete web solutions from front-end to back-end, ensuring seamless
                    functionality and integration.
                  </p>
                </div>
              </div>
            </div>
            <div className='w-full sm:w-1/2'>
              <div className='relative h-full ml-0 md:mr-10'>
                <span className='absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-purple-500 rounded-lg'></span>
                <div className='relative h-full p-5 bg-gray-100 border-2 border-purple-500 rounded-lg'>
                  <div className='flex items-center -mt-1'>
                    <h3 className='my-2 ml-3 text-lg font-bold text-purple-600'>
                      API Development & Integration
                    </h3>
                  </div>
                  <p className='mt-3 mb-1 text-xs font-medium text-purple-500 uppercase'>
                    ------------
                  </p>
                  <p className='mb-2 text-gray-600'>
                    Offering app development services to build intuitive, high-performing mobile and
                    web applications designed to meet your business needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col w-full mb-5 sm:flex-row'>
            <div className='w-full mb-10 sm:mb-0 sm:w-1/2'>
              <div className='relative h-full ml-0 mr-0 sm:mr-10'>
                <span className='absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-blue-400 rounded-lg'></span>
                <div className='relative h-full p-5 bg-gray-100 border-2 border-blue-400 rounded-lg'>
                  <div className='flex items-center -mt-1'>
                    <h3 className='my-2 ml-3 text-lg font-bold text-blue-400'>Teaching</h3>
                  </div>
                  <p className='mt-3 mb-1 text-xs font-medium text-blue-400 uppercase'>
                    ------------
                  </p>
                  <p className='mb-2 text-gray-600'>
                    Teaching services in Python and competitive programming, helping you master
                    problem-solving skills and coding techniques efficiently.
                  </p>
                </div>
              </div>
            </div>
            <div className='w-full mb-10 sm:mb-0 sm:w-1/2'>
              <div className='relative h-full ml-0 mr-0 sm:mr-10'>
                <span className='absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-yellow-400 rounded-lg'></span>
                <div className='relative h-full p-5 bg-gray-100 border-2 border-yellow-400 rounded-lg'>
                  <div className='flex items-center -mt-1'>
                    <h3 className='my-2 ml-3 text-lg font-bold text-yellow-400'>System Design</h3>
                  </div>
                  <p className='mt-3 mb-1 text-xs font-medium text-yellow-400 uppercase'>
                    ------------
                  </p>
                  <p className='mb-2 text-gray-600'>
                    Providing system design services to create scalable, efficient, and robust
                    architectures tailored to your project{"'"}s needs.
                  </p>
                </div>
              </div>
            </div>
            <div className='w-full sm:w-1/2'>
              <div className='relative h-full ml-0 md:mr-10'>
                <span className='absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-green-500 rounded-lg'></span>
                <div className='relative h-full p-5 bg-gray-100 border-2 border-green-500 rounded-lg'>
                  <div className='flex items-center -mt-1'>
                    <h3 className='my-2 ml-3 text-lg font-bold text-green-500'>
                      Database Design & Management
                    </h3>
                  </div>
                  <p className='mt-3 mb-1 text-xs font-medium text-green-500 uppercase'>
                    ------------
                  </p>
                  <p className='mb-2 text-gray-600'>
                    Structuring and optimizing databases for efficient data handling and
                    performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
