export default function Link() {
  return (
    <div className='mx-auto my-auto'>
      <p className='mx-5 font-mono text-xl font-bold'>Hire me</p>
      <a
        href='https://github.com/Naol86/Naol86/blob/main/Naol-Kasinet-FlowCV-Resume-20240830%20(4).pdf'
        download
        target='blank'
      >
        <div className='flex p-2 my-0 text-sm font-semibold text-purple-900 navbar-bg rounded-xl'>
          Download CV
          <div className='w-6 h-6 ml-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 512 512'
              fill='purple'
              className='w-full h-full'
            >
              <path d='M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM244.7 395.3l-112-112c-4.6-4.6-5.9-11.5-3.5-17.4s8.3-9.9 14.8-9.9l64 0 0-96c0-17.7 14.3-32 32-32l32 0c17.7 0 32 14.3 32 32l0 96 64 0c6.5 0 12.3 3.9 14.8 9.9s1.1 12.9-3.5 17.4l-112 112c-6.2 6.2-16.4 6.2-22.6 0z' />
            </svg>
          </div>
        </div>
      </a>
    </div>
  );
}
